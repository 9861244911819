<script setup lang="ts">
import { fetchImage } from "@/api";
import type { BlogItemList } from "@/shared/types";
import { Picture } from "@element-plus/icons-vue";
const { t, locale } = useI18n();
const localePath = useLocalePath();
interface BlogItemListType extends BlogItemList {
  maxHeight?: string;
  titleOnly?: boolean;
  imageWidth?: string;
}
const props = defineProps<BlogItemListType>();
const mountedLocale = locale.value;
const myTran = computed(
  () => props.translations[mountedLocale] || props.translations.en
);
</script>

<template>
  <div class="blog-item p-3 rd">
    <el-row :gutter="20">
      <el-col
        :span="4"
        :xs="24"
        class="image-col"
        :style="`width: ${props.imageWidth || '100%'}; max-width: ${
          props.imageWidth || '100%'
        }`"
      >
        <nuxt-link :to="localePath(`/blog/${props.idCode}`)">
          <el-image
            class="blog-item-image rd"
            :src="fetchImage(props.previewImage)"
            loading="lazy"
            :style="`width: ${props.imageWidth || '100%'}`"
            lazy
          >
            <template #placeholder>
              <div class="image-slot rd">
                <el-icon class="p-3"><Picture /></el-icon>
              </div>
            </template>
            <template #error>
              <div class="image-slot rd">
                <el-icon class="p-3"><Picture /></el-icon>
              </div>
            </template>
          </el-image>
        </nuxt-link>
      </el-col>
      <el-col :span="20" class="flex-grow" :xs="24">
        <h2>
          <nuxt-link :to="localePath(`/blog/${props.idCode}`)">
            {{ myTran.title }}
          </nuxt-link>
        </h2>
        <p
          :style="`max-height: ${props.maxHeight || 'auto'}`"
          class="parag"
          v-if="!titleOnly"
        >
          {{ myTran.description }}
        </p>
        <el-row :gutter="20">
          <el-col :span="-1" class="flex-grow">
            <el-tag
              class="mr-1 mb-1"
              type="info"
              v-for="tag in props.tags"
              :key="tag"
            >
              {{ tag }}
            </el-tag>
          </el-col>
          <el-col :span="-1" class="button-controls">
            <nuxt-link :to="localePath(`/blog/${props.idCode}`)">
              <el-button plain type="primary" round>
                {{ t("c-blog-item-readMore") }}
              </el-button>
            </nuxt-link>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss">
.blog-item {
  transition: background-color 0.3s;

  .parag {
    overflow: hidden;
  }

  .image-slot {
    width: 100px;
    height: 100px;
    background-color: var(--el-border-color-lighter);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  &-image {
    width: 100%;
    max-width: 200px;
    height: auto;
    object-fit: cover;
  }
  .button-controls {
    opacity: 0;
    transition: opacity 0.3s;
  }
  &:hover {
    background: var(--el-border-color-extra-light);
    .button-controls {
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
    &-image,
    .image-slot {
      height: 100px;
      width: auto;
      font-size: 2rem;
    }
  }
  @media (max-width: 400px) {
    .image-col {
      display: none !important;
    }
  }
}
</style>
